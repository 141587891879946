import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Editing,
  Pager,
  RequiredRule,
  Paging,
  RangeRule,
  Lookup,
  Button as GridButton,
} from "devextreme-react/data-grid";
import {
  getEmployees,
  deleteEmployee,
  createEmployee,
  updateEmployee,
} from "../../api/employees";
import { getContracts } from "../../api/contracts";
import { getBusinessUnits } from "../../api/bunits";
import { formatMessage } from "devextreme/localization";

const EmployeePage = () => {
  const [employees, setEmployees] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);

  useEffect(() => {
    getBusinessUnits()
      .then((data) => {
        setBusinessUnits(data);
      })
      .catch((error) => {
        console.error("Error fetching business units:", error);
      });
  }, []);

  useEffect(() => {
    getContracts()
      .then((data) => {
        setContracts(data);
      })
      .catch((error) => {
        console.error("Error fetching contracts:", error);
      });
  }, []);

  useEffect(() => {
    getEmployees()
      .then((data) => {
        setEmployees(data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, []);

  const handleDelete = (e) => {
    const deletedEmployee = e.data;
    deleteEmployee(deletedEmployee.employeeID)
      .then(() => {
        setEmployees((prevState) =>
          prevState.filter(
            (employee) => employee.employeeID !== deletedEmployee.employeeID
          )
        );
        console.log("Employee deleted:", deletedEmployee);
      })
      .catch((error) => {
        console.error("Error deleting employee:", error);
      });
  };

  const handleCreate = (e) => {
    const newEmployee = e.data;
    createEmployee(newEmployee);
  };

  const handleCopyRow = (rowData) => {
    createEmployee(rowData)
      .then((createdEmployee) => {
        setEmployees((prevState) => [...prevState, createdEmployee]);
        getEmployees().then((data) => {
          setEmployees(data);
        });
      })
      .catch((error) => {
        console.error("Error copying employee:", error);
      });
  };

  const handleSaveChanges = (e) => {
    const updatedEmployee = e.data;
    const id = updatedEmployee.employeeID;
    updateEmployee(updatedEmployee, id);
  };

  return (
    <div className={"content-block"}>
      <h2>{formatMessage("employees")}</h2>
      <DataGrid
        dataSource={employees}
        keyExpr="employeeID"
        className={"dx-card wide-card"}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        columnHidingEnabled={true}
        onRowRemoved={handleDelete}
        onRowInserted={handleCreate}
        onRowUpdated={handleSaveChanges}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          popup={{
            title: formatMessage("editEmployee"),
            showTitle: true,
            width: 600,
            height: 550,
          }}
        />
        <Column dataField="firstName" caption={formatMessage("firstName")}>
          <RequiredRule/>
        </Column>
        <Column dataField="lastName" caption={formatMessage("lastName")}>
          <RequiredRule />
        </Column>
        <Column dataField="contractID" caption={formatMessage("contract")}>
          <Lookup
            dataSource={contracts}
            valueExpr="contractId"
            displayExpr="name"
          />
          <RequiredRule />
        </Column>
        <Column dataField="businessUnitID" caption={formatMessage("unit")}>
          <Lookup
            dataSource={businessUnits}
            valueExpr="businessUnitID"
            displayExpr="name"
            allowClearing="true"
          />
        </Column>
        <Column dataField="skillRate" caption={formatMessage("skillRate")} dataType="number">
          <RequiredRule/>
          <RangeRule min={0} />
        </Column>
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton
            icon="copy"
            hint={formatMessage("copy")}
            onClick={(e) => handleCopyRow(e.row.data)}
          />
          <GridButton name="delete"/>
        </Column>
      </DataGrid>
    </div>
  );
};

export default EmployeePage;