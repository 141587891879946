import { SERVER_URL } from './config.js';
const accountsEndpoint = `${SERVER_URL}/api/accounts`;

export const signIn = async (email, password) => {
  const requestPassword = { email, password };
  const response = await fetch(`${accountsEndpoint}/signin`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(requestPassword),
  });

  if (response.ok) {
      const responseData = await response.json();
      localStorage.setItem('token', responseData.data.token);
      localStorage.setItem('businessId', responseData.data.businessId);  // Store token in local storage
      return { isOk: true, data: responseData };
  } else {
      return { isOk: false, message: "Authentication failed" };
  }
};

export const getUser = async () => {
  try {
      // Retrieve user data using your token
      const token = localStorage.getItem('token');
      if (!token) return { isOk: false };

      const response = await fetch(`${accountsEndpoint}/user`, {
          headers: {
              'Authorization': `Bearer ${token}`,
          },
      });

      if (response.ok) {
          const responseData = await response.json();
          return {
              isOk: true,
              data: responseData,
          };
      } else {
          return { isOk: false };
      }
  } catch {
      return { isOk: false };
  }
};

export const getUserProfile = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${accountsEndpoint}/user`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching business leaves:", error);
    throw error;
  }
};

export async function createAccount(email, password) {
  /*try {
    const response = await fetch(`${API_URL}/accounts/signup`, { // Adjust the URL if different
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });

    const result = await response.json();
    return result;
  } catch {
    return { isOk: false, message: 'Failed to create account' };
  }*/
}

export async function changePassword(email, recoveryCode) {
  // Implement this if needed
}

export async function resetPassword(email) {
  // Implement this if needed
}

//get all
/*export const signIn = async (email, password) => {
  const requestPassword = {
    email: email,
    password: password,
  };
  const response = await fetch(`${accountsEndpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestPassword),
  });
  try {
    const responseData = response.json();
    console.log(responseData);
    return {
      isOk: true,
      data: responseData
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
};

export async function signIn(email, password) {
  try {
    validatePassword(email, password);
    console.log(email, password);

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}*/
