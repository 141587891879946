import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    
    const signIn = useCallback(async (email, password) => {
        const result = await sendSignInRequest(email, password);
        if (result.isOk) {
            setUser(result.data);
        }
        return result;
    }, []);


    useEffect(() => {
        (async function () {
            const result = await getUser();
            if (result.isOk) {
                setUser(result.data);
            }
            setLoading(false);
        })();
    }, []);

    
    const signOut = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('businessId');
        localStorage.removeItem('currentView');
        localStorage.removeItem('orderModeSelected');
        localStorage.removeItem('selectedMonth');
        localStorage.removeItem('optionDate');
        localStorage.removeItem('selectedCalendars');
        sessionStorage.removeItem('locale');
        setUser(undefined);
    }, []);

    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
    );
}

export { AuthProvider, useAuth };

