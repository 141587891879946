import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Lookup,
  RequiredRule,
  Button as GridButton,
  CustomRule,
} from "devextreme-react/data-grid";
import {
  getEmployeeLeaves,
  updateEmployeeLeave,
  deleteEmployeeLeave,
  createEmployeeLeave,
} from "../../api/eleaves";
import { getEmployees } from "../../api/employees";
import { emplLeaveType } from "../enums";

import { formatMessage } from "devextreme/localization";
const currentLocale = sessionStorage.getItem("locale") || "en";

const EmployeeLeavesPage = () => {
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [employees, setEmployees] = useState([]);


  useEffect(() => {
    getEmployees()
      .then((data) => {
        setEmployees(data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, []);

  useEffect(() => {
    getEmployeeLeaves()
      .then((data) => {
        setEmployeeLeaves(data);
      })
      .catch((error) => {
        console.error("Error fetching employee leaves:", error);
      });
  }, []);

  const handleDelete = (e) => {
    const deletedEmployeeLeave = e.data;
    deleteEmployeeLeave(deletedEmployeeLeave.leaveId)
      .then(() => {
        setEmployeeLeaves((prevState) =>
          prevState.filter(
            (leave) => leave.leaveId !== deletedEmployeeLeave.leaveId
          )
        );
        console.log("Employee leave deleted:", deletedEmployeeLeave);
      })
      .catch((error) => {
        console.error("Error deleting employee leave:", error);
      });
  };

    /*
    // Subtract 2 hours from start and end dates - need to FIX
    const startDate = new Date(newELeave.startDate);
    startDate.setHours(startDate.getHours() + 2);

    const endDate = new Date(newELeave.endDate);
    endDate.setHours(endDate.getHours() + 2);
    */

  const handleCreate = (e) => {
    const newELeave = e.data;
    createEmployeeLeave(newELeave)
    .then(()=>{
      getEmployeeLeaves()
      .then((data) => {
        setEmployeeLeaves(data);
      })
    })
  };

  const handleSaveChanges = (e) => {
    const updatedELeave = e.data;
    const id = updatedELeave.leaveId;
    updateEmployeeLeave(updatedELeave, id);
  };

  const handleCopyRow = (rowData) => {
    createEmployeeLeave(rowData)
      .then((createdLeave) => {
        setEmployeeLeaves((prevState) => [...prevState, createdLeave]);
        getEmployeeLeaves().then((data) => {
          setEmployeeLeaves(data);
        });
      })
      .catch((error) => {
        console.error("Error copying employee leave", error);
      });
  };

  function validateReservationDates({ data }) {
    const startDate = data.startDate;
    const endDate = data.endDate;
    if (!startDate || !endDate) {
      return true;
    }
    return new Date(startDate) < new Date(endDate);
  }

  return (
    <div className={"content-block"}>
      <h2>{formatMessage("employeeLeaves")}</h2>
      <DataGrid
        dataSource={employeeLeaves}
        keyExpr="leaveId"
        className={"dx-card wide-card"}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        rowAlternationEnabled={true}
        onRowRemoved={handleDelete}
        onRowInserted={handleCreate}
        onRowUpdated={handleSaveChanges}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          popup={{
            title: formatMessage("editEmployeeLeave"),
            showTitle: true,
            width: 600,
            height: 550,
          }}
        >
        </Editing>
        <Column dataField="employeeID" caption={formatMessage("employee")}>
          <Lookup
            dataSource={employees}
            valueExpr="employeeID"
            displayExpr="fullName"
          />
          <RequiredRule />
        </Column>
        <Column dataField="leaveType" caption={formatMessage("type")}>
          <Lookup dataSource={emplLeaveType} valueExpr="value" displayExpr={currentLocale}/>
          <RequiredRule />
        </Column>
        <Column
          dataField="startDate"
          caption={formatMessage("openingStart")}
          dataType="datetime"
          format="dd.MM.yyyy, HH:mm"
        >
          <RequiredRule/>
          <CustomRule
            validationCallback={validateReservationDates}
            message={formatMessage("startDateValidationMessage")}
          />
        </Column>
        <Column
          dataField="endDate"
          caption={formatMessage("openingEnd")}
          dataType="datetime"
          format="dd.MM.yyyy, HH:mm"
          >
          <RequiredRule />
          <CustomRule
            validationCallback={validateReservationDates}
            message={formatMessage("endDateValidationMessage")}
          />
        </Column>
        <Column type="buttons">
          <GridButton name="edit" hint={formatMessage("edit")}/>
          <GridButton
            icon="copy"
            hint="Copy"
            onClick={(e) => handleCopyRow(e.row.data)}
          />
          <GridButton name="delete" />
        </Column>
      </DataGrid>
    </div>
  );
};

export default EmployeeLeavesPage;

