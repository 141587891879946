import React, { useCallback, useState, useEffect } from "react";
import DataGrid, {
  Column,
  Editing,
  Pager,
  Form,Item,
  RequiredRule,
  Paging,
  Lookup,
  CustomRule,
  Button as GridButton,
} from "devextreme-react/data-grid";
import {
  getBusinesLeaves,
  deleteBusinesLeave,
  updateBusinesLeave,
  createBusinesLeave,
} from "../../api/bleaves";
import { getBusinessUnits } from "../../api/bunits";
import { leaveType } from "../enums";

import { formatMessage } from "devextreme/localization";
const currentLocale = sessionStorage.getItem("locale") || "en";

const BusinessLeavesPage = () => {
  const [businessLeaves, setBusinessLeaves] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [currentRange, setCurrentRange] = useState([null, null]);
  const [isNewRow, setIsNewRow] = useState(false);
  const [leaveData, setLeaveData] = useState([null, null, null]);
  const [validationStatus, setValidationStatus] = useState(true);
  const {allDayMode, setAllDayMode} = useState(true);

  useEffect(() => {
    getBusinessUnits()
      .then((data) => {
        setBusinessUnits(data);
      })
      .catch((error) => {
        console.error("Error fetching business units:", error);
      });
  }, []);

  useEffect(() => {
    getBusinesLeaves()
      .then((data) => {
        setBusinessLeaves(data);
      })
      .catch((error) => {
        console.error("Error fetching business leaves:", error);
      });
  }, []);

  const handleDelete = (e) => {
    const deletedBusinessLeave = e.data;
    deleteBusinesLeave(deletedBusinessLeave.businessUnitLeaveID)
      .then(() => {
        setBusinessLeaves((prevState) =>
          prevState.filter(
            (leave) =>
              leave.businessUnitLeaveID !==
              deletedBusinessLeave.businessUnitLeaveID
          )
        );
        console.log("Business leave deleted:", deletedBusinessLeave);
      })
      .catch((error) => {
        console.error("Error deleting business leave:", error);
      });
  };

  const handleCreate = (e) => {
    const newBLeave = {
      leaveType: e.data.leaveType,
      startDate: e.data.startDate,
      endDate: e.data.endDate,
      businessUnitID: e.data.businessUnitID,
      businessID: localStorage.getItem("businessId"),
    };
    createBusinesLeave(newBLeave).then(() => {
      getBusinesLeaves().then((data) => {
        setBusinessLeaves(data);
      });
    });
  };

 /* const handleDateChanges = async (e) => {
   
      const updatedBLeave = {
        leaveType: e.data.leaveType,
        startDate: e.data.startDate,
        endDate: e.data.endDate,
        businessUnitID: e.data.businessUnitID,
        businessID: localStorage.getItem("businessId"),
      };

      await updateBusinesLeave(updatedBLeave, leaveData[0]);
      const data = await getBusinesLeaves();
      setBusinessLeaves(data);
  };*/

  function validateReservationDates({ data }) {
    const startDate = data.startDate;
    const endDate = data.endDate;
    if (!startDate || !endDate) {
      return true;
    }
    return new Date(startDate) < new Date(endDate);
  }

  const handleSaveChanges = (e) => {
    console.log(e.data);
    const updatedBLeave = {
      leaveType: e.data.leaveType,
      startDate: e.data.startDate,
      endDate: e.data.endDate,
      businessUnitID: e.data.businessUnitID,
      businessID: localStorage.getItem("businessId"),
    };
    updateBusinesLeave(updatedBLeave, leaveData[0]);
    getBusinesLeaves().then((data) => {
      setBusinessLeaves(data);
    });
  };

  const handleCopyRow = (rowData) => {
    const copiedRow = {
      leaveType: rowData.leaveType,
      startDate: rowData.startDate,
      endDate: rowData.endDate,
      businessUnitID: rowData.businessUnitID,
      businessID: localStorage.getItem("businessId"),
    };
    createBusinesLeave(copiedRow)
      .then((createdUnitLeave) => {
        setBusinessLeaves((prevState) => [...prevState, createdUnitLeave]);
        getBusinesLeaves().then((data) => {
          setBusinessLeaves(data);
        });
      })
      .catch((error) => {
        console.error("Error copying business leave:", error);
      });
  };

  const onEditorPreparing = (e) => {
    if (e.dataField === "startDate" || e.dataField === "endDate") {
      e.editorName = "dxDateBox";
      e.editorOptions = {
        ...e.editorOptions,
        visible: !allDayMode, // Set visibility based on allDayMode
      };
    }
  };

  const onEditingStart = (e) => {
    setValidationStatus(true);
    setLeaveData([
      e.data.businessUnitLeaveID,
      e.data.businessUnitID,
      e.data.leaveType,
    ]);
    setCurrentRange([e.data.startDate, e.data.endDate]);
  };

  const onInitNewRow = () => {
    setValidationStatus(true);
    setIsNewRow(true);
    setLeaveData([null, null, null]);
    setCurrentRange([null, null]);
  };

  return (
    <div className={"content-block"}>
      <h2>{formatMessage("businessLeaves")}</h2>
      <DataGrid
        dataSource={businessLeaves}
        keyExpr="businessUnitLeaveID"
        className={"dx-card wide-card"}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        rowAlternationEnabled={true}
        onRowRemoved={handleDelete}
        onRowInserted={handleCreate}
        onRowUpdated={handleSaveChanges}
        onEditorPreparing={onEditorPreparing}
        onEditingStart={onEditingStart}
        onInitNewRow={onInitNewRow}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          popup={{
            title: formatMessage("editBusinessLeave"),
            showTitle: true,
            width: 600,
            height: 550,
          }}
        >
          <Form colCount={2}>
            <Item colSpan={1} dataField="businessUnitID" dataType="string">
              <RequiredRule  />
            </Item>
            <Item colSpan={1} dataField="leaveType" dataType="string">
              <RequiredRule  />
            </Item>
            <Item colSpan={1} dataField="startDate" dataType="dateTime">
              <RequiredRule  />
            </Item>
            <Item colSpan={1} dataField="endDate" dataType="dateTime">
              <RequiredRule  />
            </Item>
          </Form>
        </Editing>
        <Column dataField="businessUnitID" caption={formatMessage("unit")}>
          <Lookup
            dataSource={businessUnits}
            valueExpr="businessUnitID"
            displayExpr="name"
          />
        </Column>
        <Column dataField="leaveType" caption={formatMessage("type")}>
          <Lookup dataSource={leaveType} valueExpr="value" displayExpr={currentLocale} />
        </Column>
        <Column
          dataField="startDate"
          caption={formatMessage("openingStart")}
          dataType="datetime"
          format="dd.MM.yyyy, HH:mm"
        >
          <CustomRule
            validationCallback={validateReservationDates}
            message={formatMessage("startDateValidationMessage")}
          />
        </Column>
        <Column
          dataField="endDate"
          caption={formatMessage("openingEnd")}
          dataType="datetime"
          format="dd.MM.yyyy, HH:mm"
        >
          <CustomRule
            validationCallback={validateReservationDates}
            message={formatMessage("endDateValidationMessage")}
          />
        </Column>
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton
            icon="copy"
            hint={formatMessage("copy")}
            onClick={(e) => handleCopyRow(e.row.data)}
          />
          <GridButton name="delete"/>
        </Column>
      </DataGrid>
    </div>
  );
};

export default BusinessLeavesPage;
