import React, { useState, useRef, useCallback, useEffect } from "react";
import DataGrid, { Column, Lookup, FilterRow } from "devextreme-react/data-grid";
import DateRangeBox from "devextreme-react/date-range-box";
import PivotGrid, { FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { getEmployees } from "../../api/employees";
import { getStatisticData } from "../../api/statistic.js";
import { getAllPivotShifts, getPivotStatisticData } from "../../api/statistic.js";
import { formatMessage } from "devextreme/localization";
import ColourCell from "./colourCell.js";
import "./statistic.scss";


const StatisticPage = () => {
  const getCurrentMonthRange = () => {
    const start = new Date();
    start.setDate(1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(start);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);
    end.setHours(23, 59, 59, 999);

    return [start, end];
  };
   
  const [dateRange, setDateRange] = useState(getCurrentMonthRange());
  const [statisticsData, setStatisticsData] = useState([]);
  const [pivotData, setPivotData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const dataGridRef = useRef(null);

  useEffect(() => {
    getEmployees()
      .then((data) => {
        setEmployees(data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, []);

  useEffect(() => {
    getStatisticData()
      .then((data) => {
        setStatisticsData(data);
      })
      .catch((error) => {
        console.error("Error fetching statistics data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const pivotShiftData = await getAllPivotShifts();
  
        const pivotStatisticData = await getPivotStatisticData(); 
        const combinedData = [...pivotShiftData, ...pivotStatisticData];
  
        // Set the combined pivot data to the state
        setPivotData(combinedData);

      } catch (error) {
        console.error("Error fetching pivot data or additional data:", error);
      }
    };
  
    fetchData();
  }, []); 

  useEffect(() => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance;
      const [startDate, endDate] = dateRange;

      dataGrid.filter([
        ["startMonthDate", ">=", startDate],
        "and",
        ["startMonthDate", "<=", endDate]
      ]);
    }
  }, [dateRange]);


  const dataSource = new PivotGridDataSource({
    fields: [
        { 
            dataField: 'name', 
            area: 'row', 
            caption:  formatMessage("employee")},
        { 
            dataField: 'date', 
            area: 'column', 
            dataType: 'date', 
            format: 'dd.MM.yyyy', 
            caption: formatMessage("date") },
        { 
            dataField: 'category', 
            area: 'row', 
            caption: formatMessage("category")  },
        { 
            dataField: 'businessUnitID', 
            area: 'row', 
            caption: formatMessage("unit")},
        { 
            dataField: 'duration', 
            dataType: 'number', 
            summaryType: 'sum', 
            area: 'data', 
            isMeasure: true,
            caption: formatMessage("hours")},
    ],
    store: pivotData
  });

  const onDateRangeChanged = useCallback(({ value }) => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance;
      const [startDate, endDate] = value;

      if (startDate && endDate) {
        dataGrid.filter([
          ["startMonthDate", ">=", startDate],
          "and",
          ["startMonthDate", "<=", endDate]
        ]);
      } else {
        dataGrid.clearFilter();
      }
    }
    setDateRange(value);
  }, []);

  return (
    <div className={"content-block"}>
      <h2>{formatMessage("statistics")}</h2>
      <div className="range-box-container">
        <label className="date-range-label">{formatMessage("filterByDateRange")}:</label>
        <DateRangeBox
          value={dateRange}
          onValueChanged={onDateRangeChanged}
          startDateLabel={formatMessage("startDate")}
          endDateLabel={formatMessage("endDate")}
          displayFormat="dd.MM.yyyy"
        />
      </div>
      <DataGrid
        ref={dataGridRef}
        dataSource={statisticsData}
        keyExpr="id"
        showBorders={true}
      >
        <FilterRow visible={true} />
        <Column dataField="employeeID" caption={formatMessage("employee")}>
          <Lookup
            dataSource={employees}
            valueExpr="employeeID"
            displayExpr="fullName"
          />
        </Column>
        <Column
          dataField="workingHours"
          caption={formatMessage("workingHours")}
        />
        <Column
          dataField="workingHoursPerMonth"
          caption={formatMessage("workingHoursPerMonth")}
        />
        <Column
          dataField="currentTotalShiftHours"
          caption={formatMessage("currentTotalShiftHours")}
        />
        <Column
          dataField="leaveHoursPerMonth"
          caption={formatMessage("leaveHoursPerMonth")}
        />
        <Column
          dataField="remainingWorkHours"
          caption={formatMessage("remainingWorkHours")}
          cellRender={data => <ColourCell value={data.value} />}
        />
        <Column
          dataField="startMonthDate"
          caption={formatMessage("month")}
          dataType="date"
          format="MM. yyyy"
        />
      </DataGrid>
      <h2>Pivot</h2>
      <div>
            <PivotGrid
                dataSource={dataSource}
                allowSorting={true}
                allowSortingBySummary={true}
                allowFiltering={true}
                showBorders={true}
            >
                <FieldPanel
                    showColumnFields={true}
                    showRowFields={true}
                    allowFieldDragging={true}
                    visible={true}
                />
                <FieldChooser enabled={true} />
            </PivotGrid>
        </div>
    </div>
  );
};

export default StatisticPage;
/*
import React, { useState } from 'react';
import {
  PivotGrid,
  HeaderFilter,
  Search,
  FieldChooser,
  FieldPanel,
} from 'devextreme-react/pivot-grid';
import { CheckBox } from 'devextreme-react/check-box';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import XmlaStore from 'devextreme/ui/pivot_grid/xmla_store';

const dataSource = new PivotGridDataSource({
  fields: [
    { dataField: '[Product].[Category]', area: 'column' },
    { dataField: '[Product].[Subcategory]', area: 'column' },
    { dataField: '[Customer].[City]', area: 'row' },
    { dataField: '[Measures].[Internet Total Product Cost]', area: 'data', format: 'currency' },
    {
      dataField: '[Customer].[Country]',
      area: 'filter',
      filterValues: ['[Customer].[Country].&[United Kingdom]'],
    },
    {
      dataField: '[Ship Date].[Calendar Year]',
      area: 'filter',
      filterValues: ['[Ship Date].[Calendar Year].&[2004]'],
    },
  ],
  store: new XmlaStore({
    url: 'https://demos.devexpress.com/Services/OLAP/msmdpump.dll',
    catalog: 'Adventure Works DW Standard Edition',
    cube: 'Adventure Works',
  }),
});
const App = () => {
  const [searchEnabled, setSearchEnabled] = useState(true);
  const [showRelevantValues, setShowRelevantValues] = useState(true);
  return (
    <div>
      <PivotGrid
        id="sales"
        allowFiltering={true}
        allowSorting={true}
        allowSortingBySummary={true}
        height={570}
        showBorders={true}
        dataSource={dataSource}
      >
        <HeaderFilter
          showRelevantValues={showRelevantValues}
          width={300}
          height={400}
        >
          <Search enabled={searchEnabled} />
        </HeaderFilter>
        <FieldChooser allowSearch={true} />
        <FieldPanel visible={true} />
      </PivotGrid>
      <div className="options">
        <div className="caption">Options</div>
        <div className="options-container">
          <div className="option">
            <CheckBox
              value={searchEnabled}
              text="Allow Search"
              onValueChange={setSearchEnabled}
            />
          </div>
          <div className="option">
            <CheckBox
              value={showRelevantValues}
              text="Show Relevant Values"
              onValueChange={setShowRelevantValues}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default App;*/
/*
import React from 'react';
import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';


const statisticsData = [
        {
            "id": 216,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 1,
            "currentTotalShiftHours": 165,
            "workingHoursPerMonth": 165,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 0.0,
            "businessID": 1
        },
        {
            "id": 217,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 2,
            "currentTotalShiftHours": 127.5,
            "workingHoursPerMonth": 165,
            "leaveHoursPerMonth": 37.5,
            "remainingWorkHours": 0.0,
            "businessID": 1
        },
        {
            "id": 218,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 3,
            "currentTotalShiftHours": 137,
            "workingHoursPerMonth": 176,
            "leaveHoursPerMonth": 7.0,
            "remainingWorkHours": 32.0,
            "businessID": 1
        },
        {
            "id": 219,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 4,
            "currentTotalShiftHours": 137,
            "workingHoursPerMonth": 165,
            "leaveHoursPerMonth": 7.0,
            "remainingWorkHours": 21.0,
            "businessID": 1
        },
        {
            "id": 220,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 5,
            "currentTotalShiftHours": 37.5,
            "workingHoursPerMonth": 176,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 138.5,
            "businessID": 1
        },
        {
            "id": 221,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 6,
            "currentTotalShiftHours": 144.5,
            "workingHoursPerMonth": 162,
            "leaveHoursPerMonth": 4.0,
            "remainingWorkHours": 13.5,
            "businessID": 1
        },
        {
            "id": 222,
            "startMonthDate": "2024-08-01T00:00:00",
            "employeeID": 7,
            "currentTotalShiftHours": 46,
            "workingHoursPerMonth": 88,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 42.0,
            "businessID": 1
        },
        {
            "id": 223,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 1,
            "currentTotalShiftHours": 105,
            "workingHoursPerMonth": 157.5,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 52.5,
            "businessID": 1
        },
        {
            "id": 224,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 2,
            "currentTotalShiftHours": 105,
            "workingHoursPerMonth": 157.5,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 52.5,
            "businessID": 1
        },
        {
            "id": 225,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 3,
            "currentTotalShiftHours": 139,
            "workingHoursPerMonth": 168,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 29.0,
            "businessID": 1
        },
        {
            "id": 226,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 4,
            "currentTotalShiftHours": 139,
            "workingHoursPerMonth": 157.5,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 18.5,
            "businessID": 1
        },
        {
            "id": 227,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 5,
            "currentTotalShiftHours": 105,
            "workingHoursPerMonth": 168,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 63.0,
            "businessID": 1
        },
        {
            "id": 228,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 6,
            "currentTotalShiftHours": 138,
            "workingHoursPerMonth": 150,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 12.0,
            "businessID": 1
        },
        {
            "id": 229,
            "startMonthDate": "2024-09-01T00:00:00",
            "employeeID": 7,
            "currentTotalShiftHours": 42,
            "workingHoursPerMonth": 84,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 42.0,
            "businessID": 1
        },
        {
            "id": 230,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 1,
            "currentTotalShiftHours": 120,
            "workingHoursPerMonth": 172.5,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 52.5,
            "businessID": 1
        },
        {
            "id": 231,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 2,
            "currentTotalShiftHours": 112.5,
            "workingHoursPerMonth": 172.5,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 60.0,
            "businessID": 1
        },
        {
            "id": 232,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 3,
            "currentTotalShiftHours": 153,
            "workingHoursPerMonth": 184,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 31.0,
            "businessID": 1
        },
        {
            "id": 233,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 4,
            "currentTotalShiftHours": 153,
            "workingHoursPerMonth": 172.5,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 19.5,
            "businessID": 1
        },
        {
            "id": 234,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 5,
            "currentTotalShiftHours": 112.5,
            "workingHoursPerMonth": 184,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 71.5,
            "businessID": 1
        },
        {
            "id": 235,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 6,
            "currentTotalShiftHours": 150,
            "workingHoursPerMonth": 162,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 12.0,
            "businessID": 1
        },
        {
            "id": 236,
            "startMonthDate": "2024-10-01T00:00:00",
            "employeeID": 7,
            "currentTotalShiftHours": 46,
            "workingHoursPerMonth": 92,
            "leaveHoursPerMonth": 0.0,
            "remainingWorkHours": 46.0,
            "businessID": 1
        }
    ];

    const shiftsData = [
      {
        "shiftId": 4165,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-15T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-15T08:30:00",
        "shiftEndTime": "2024-08-15T16:00:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4166,
        "name": "Soltes Peter : Dracik",
        "date": "2024-08-16T00:00:00",
        "employeeID": 1,
        "shiftStartTime": "2024-08-16T08:00:00",
        "shiftEndTime": "2024-08-16T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4167,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-16T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-16T08:00:00",
        "shiftEndTime": "2024-08-16T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4168,
        "name": "Soltes Peter : Dracik",
        "date": "2024-08-19T00:00:00",
        "employeeID": 1,
        "shiftStartTime": "2024-08-19T08:00:00",
        "shiftEndTime": "2024-08-19T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4169,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-19T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-19T08:00:00",
        "shiftEndTime": "2024-08-19T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4170,
        "name": "Soltes Peter : Dracik",
        "date": "2024-08-20T00:00:00",
        "employeeID": 1,
        "shiftStartTime": "2024-08-20T08:00:00",
        "shiftEndTime": "2024-08-20T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4171,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-20T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-20T08:00:00",
        "shiftEndTime": "2024-08-20T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4172,
        "name": "Soltes Peter : Dracik",
        "date": "2024-08-21T00:00:00",
        "employeeID": 1,
        "shiftStartTime": "2024-08-21T08:00:00",
        "shiftEndTime": "2024-08-21T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4173,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-21T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-21T08:00:00",
        "shiftEndTime": "2024-08-21T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4174,
        "name": "Soltes Peter : Dracik",
        "date": "2024-08-22T00:00:00",
        "employeeID": 1,
        "shiftStartTime": "2024-08-22T08:30:00",
        "shiftEndTime": "2024-08-22T16:00:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4175,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-22T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-22T08:30:00",
        "shiftEndTime": "2024-08-22T16:00:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4176,
        "name": "Soltes Peter : Dracik",
        "date": "2024-08-23T00:00:00",
        "employeeID": 1,
        "shiftStartTime": "2024-08-23T08:00:00",
        "shiftEndTime": "2024-08-23T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4177,
        "name": "Hruska Pavol : Dracik",
        "date": "2024-08-23T00:00:00",
        "employeeID": 2,
        "shiftStartTime": "2024-08-23T08:00:00",
        "shiftEndTime": "2024-08-23T15:30:00",
        "businessUnitID": 1,
        "businessID": 1
    },
    {
        "shiftId": 4179,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-01T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-01T09:00:00",
        "shiftEndTime": "2024-08-01T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4180,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-02T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-02T08:00:00",
        "shiftEndTime": "2024-08-02T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4181,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-02T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-02T08:00:00",
        "shiftEndTime": "2024-08-02T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4182,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-05T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-05T09:00:00",
        "shiftEndTime": "2024-08-05T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4183,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-05T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-05T09:00:00",
        "shiftEndTime": "2024-08-05T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4184,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-06T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-06T08:00:00",
        "shiftEndTime": "2024-08-06T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4185,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-06T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-06T08:00:00",
        "shiftEndTime": "2024-08-06T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4186,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-07T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-07T09:00:00",
        "shiftEndTime": "2024-08-07T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4187,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-07T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-07T09:00:00",
        "shiftEndTime": "2024-08-07T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4188,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-08T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-08T09:00:00",
        "shiftEndTime": "2024-08-08T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4189,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-08T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-08T09:00:00",
        "shiftEndTime": "2024-08-08T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4190,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-09T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-09T08:00:00",
        "shiftEndTime": "2024-08-09T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4191,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-09T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-09T08:00:00",
        "shiftEndTime": "2024-08-09T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4192,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-12T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-12T09:00:00",
        "shiftEndTime": "2024-08-12T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4193,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-12T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-12T09:00:00",
        "shiftEndTime": "2024-08-12T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4194,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-13T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-13T08:00:00",
        "shiftEndTime": "2024-08-13T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4195,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-13T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-13T08:00:00",
        "shiftEndTime": "2024-08-13T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4196,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-14T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-14T09:00:00",
        "shiftEndTime": "2024-08-14T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4197,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-14T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-14T09:00:00",
        "shiftEndTime": "2024-08-14T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4198,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-15T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-15T09:00:00",
        "shiftEndTime": "2024-08-15T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4200,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-16T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-16T08:00:00",
        "shiftEndTime": "2024-08-16T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4201,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-16T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-16T08:00:00",
        "shiftEndTime": "2024-08-16T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4202,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-19T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-19T09:00:00",
        "shiftEndTime": "2024-08-19T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4203,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-19T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-19T09:00:00",
        "shiftEndTime": "2024-08-19T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4204,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-20T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-20T08:00:00",
        "shiftEndTime": "2024-08-20T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4205,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-20T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-20T08:00:00",
        "shiftEndTime": "2024-08-20T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4206,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-21T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-21T09:00:00",
        "shiftEndTime": "2024-08-21T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4207,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-21T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-21T09:00:00",
        "shiftEndTime": "2024-08-21T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4208,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-22T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-22T09:00:00",
        "shiftEndTime": "2024-08-22T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4209,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-22T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-22T09:00:00",
        "shiftEndTime": "2024-08-22T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4210,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-23T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-23T08:00:00",
        "shiftEndTime": "2024-08-23T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4211,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-23T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-23T08:00:00",
        "shiftEndTime": "2024-08-23T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4212,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-26T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-26T09:00:00",
        "shiftEndTime": "2024-08-26T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4213,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-26T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-26T09:00:00",
        "shiftEndTime": "2024-08-26T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4214,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-27T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-27T08:00:00",
        "shiftEndTime": "2024-08-27T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4215,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-27T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-27T08:00:00",
        "shiftEndTime": "2024-08-27T15:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4216,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-28T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-28T09:00:00",
        "shiftEndTime": "2024-08-28T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4217,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-28T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-28T09:00:00",
        "shiftEndTime": "2024-08-28T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4218,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-29T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-29T09:00:00",
        "shiftEndTime": "2024-08-29T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4219,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-29T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-29T09:00:00",
        "shiftEndTime": "2024-08-29T16:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4220,
        "name": "Varnik Patrik : Goldeck",
        "date": "2024-08-30T00:00:00",
        "employeeID": 3,
        "shiftStartTime": "2024-08-30T08:00:00",
        "shiftEndTime": "2024-08-30T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4221,
        "name": "Cibulka Jaro : Goldeck",
        "date": "2024-08-30T00:00:00",
        "employeeID": 4,
        "shiftStartTime": "2024-08-30T08:00:00",
        "shiftEndTime": "2024-08-30T13:00:00",
        "businessUnitID": 2,
        "businessID": 1
    },
    {
        "shiftId": 4222,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-16T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-16T12:00:00",
        "shiftEndTime": "2024-08-16T15:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4223,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-16T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-16T07:30:00",
        "shiftEndTime": "2024-08-16T11:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4224,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-01T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-01T07:30:00",
        "shiftEndTime": "2024-08-01T13:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4225,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-01T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-01T13:30:00",
        "shiftEndTime": "2024-08-01T15:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4226,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-02T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-02T07:30:00",
        "shiftEndTime": "2024-08-02T13:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4227,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-02T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-02T13:30:00",
        "shiftEndTime": "2024-08-02T15:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4228,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-03T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-03T09:00:00",
        "shiftEndTime": "2024-08-03T12:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4229,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-05T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-05T07:00:00",
        "shiftEndTime": "2024-08-05T13:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4230,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-05T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-05T13:00:00",
        "shiftEndTime": "2024-08-05T15:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4231,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-06T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-06T07:00:00",
        "shiftEndTime": "2024-08-06T13:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4232,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-06T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-06T13:00:00",
        "shiftEndTime": "2024-08-06T15:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4233,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-07T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-07T07:30:00",
        "shiftEndTime": "2024-08-07T13:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4234,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-07T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-07T13:30:00",
        "shiftEndTime": "2024-08-07T15:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4235,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-08T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-08T07:30:00",
        "shiftEndTime": "2024-08-08T13:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4236,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-08T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-08T13:30:00",
        "shiftEndTime": "2024-08-08T15:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4237,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-09T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-09T07:30:00",
        "shiftEndTime": "2024-08-09T13:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4238,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-09T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-09T13:30:00",
        "shiftEndTime": "2024-08-09T15:30:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4239,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-10T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-10T09:00:00",
        "shiftEndTime": "2024-08-10T12:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4240,
        "name": "Cibulkova Lenka : Nova",
        "date": "2024-08-12T00:00:00",
        "employeeID": 6,
        "shiftStartTime": "2024-08-12T07:00:00",
        "shiftEndTime": "2024-08-12T13:00:00",
        "businessUnitID": 4,
        "businessID": 1
    },
    {
        "shiftId": 4241,
        "name": "Brigadnik Stano : Nova",
        "date": "2024-08-12T00:00:00",
        "employeeID": 7,
        "shiftStartTime": "2024-08-12T13:00:00",
        "shiftEndTime": "2024-08-12T15:00:00",
        "businessUnitID": 4,
        "businessID": 1
    }
  ];
  
  const dataSource = new PivotGridDataSource({
    fields: [
        { dataField: 'employeeID', area: 'row', caption: 'Employee ID' },
        { dataField: 'date', area: 'column', dataType: 'date', format: 'dd.MM.yyyy', caption: 'Date' },
        { dataField: 'shiftStartTime', area: 'data', caption: 'Shift Start Time' },
        { dataField: 'shiftEndTime', area: 'data', caption: 'Shift End Time' },
        { dataField: 'businessUnitID', area: 'filter', caption: 'Business Unit ID' },
    ],
    store: shiftsData
});

export default function App() {
    return (
        <div>
            <PivotGrid
                dataSource={dataSource}
                allowSorting={true}
                allowSortingBySummary={true}
                allowFiltering={true}
                showBorders={true}
            >
                <FieldChooser enabled={true} />
            </PivotGrid>
        </div>
    );
}*/