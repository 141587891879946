import { SERVER_URL } from "./config.js";

const businesUnitEndpoint = `${SERVER_URL}/api/bunits`;
const openinghHoursEndpoint = `${SERVER_URL}/api/opening-hours`;

// Get all business units
export const getBusinessUnits = () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");

  return fetch(`${businesUnitEndpoint}/get-all/${businessID}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => data.bUnits)
    .catch((error) => {
      console.error("Error fetching business units:", error);
      throw error;
    });
};

// Get all business units with opening hours
export const getBusinessUnitsAndOpenHours = () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");

  return fetch(`${businesUnitEndpoint}/openinghours/${businessID}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => data)
    .catch((error) => {
      console.error("Error fetching business units:", error);
      throw error;
    });
};

// Update unit opening hours
export const updateUnitOpeningHours = (updatedhours) => {
  const token = localStorage.getItem("token");
  const updateRequest = updatedhours.map((item) => ({
    unitOpeningHoursId: item.unitOpeningHoursId,
    openingHoursStart: item.openingHoursStart,
    openingHoursEnd: item.openingHoursEnd,
    isOpen: item.isOpen,
    dayOfWeek: item.dayOfWeek,
    businessUnitID: item.businessUnitID,
    businessID: localStorage.getItem("businessId"),
  }));

  console.log(updateRequest);
  fetch(`${openinghHoursEndpoint}/all`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updateRequest),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update business unit");
      }
      console.log("Business unit updated successfully");
    })
    .catch((error) => {
      console.error("Error updating business unit:", error);
    });
};

// Update business unit
export const updateBusinessUnit = (updatedBusinessUnit, businessUnitID) => {
  const token = localStorage.getItem("token");
  const putBUnitrequest = {
    name: updatedBusinessUnit.name,
    numberOfEmployees: updatedBusinessUnit.numberOfEmployees,
    businessID: localStorage.getItem("businessId"),
  };

  fetch(`${businesUnitEndpoint}/${businessUnitID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(putBUnitrequest),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update business unit");
      }
      console.log("Business unit updated successfully");
    })
    .catch((error) => {
      console.error("Error updating business unit:", error);
    });
};

// Delete a business unit by ID
export const deleteBusinessUnit = (businessUnitID) => {
  const token = localStorage.getItem("token");

  return fetch(`${businesUnitEndpoint}/${businessUnitID}`, {
    method: "DELETE",
    "Access-Control-Allow-Origin": "*",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete business unit");
      }
    })
    .catch((error) => {
      console.error("Error deleting business unit:", error);
      throw error;
    });
};

// Create a new business unit
export const createBusinessUnit = (newBusinessUnit) => {
  const token = localStorage.getItem("token");
  const newBUnitrequest = {
    name: newBusinessUnit.name,
    numberOfEmployees: newBusinessUnit.numberOfEmployees,
    businessID: localStorage.getItem("businessId"),
    openingsHours: [],
  };

  return fetch(`${businesUnitEndpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newBUnitrequest),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to create business unit");
      }
      return response.json();
    })
    .then((data) => {
      console.log("New business unit created successfully", data);
      return data.businessUnitID;
    })
    .catch((error) => {
      console.error("Error creating business unit:", error);
      throw error;
    });
};

// Create opening hours
export const createOpeningsHours = (openingsHours, businessUnitID) => {
  const token = localStorage.getItem("token");
  const postRequest = openingsHours.map((item) => ({
    openingHoursStart: item.openingHoursStart,
    openingHoursEnd: item.openingHoursEnd,
    isOpen: item.isOpen,
    dayOfWeek: item.dayOfWeek,
    businessUnitID: businessUnitID,
    businessID: localStorage.getItem("businessId"),
  }));

  return fetch(`${openinghHoursEndpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postRequest),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to create business unit");
      }
      console.log("New business unit created successfully");
    })
    .catch((error) => {
      console.error("Error creating business unit:", error);
    });
};
