import { SERVER_URL } from "./config.js";
import { workCalendar } from "../pages/enums.js";
import { getEmployees } from "./employees.js";
import { getBusinessUnits } from "./bunits.js";
import { formatMessage } from "devextreme/localization";

const statisticsEndpoint = `${SERVER_URL}/api/statistics`;
const shiftsEndpoint = `${SERVER_URL}/api/shifts`;

// Get all statistics data
export const getStatisticData = async () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${statisticsEndpoint}/get-all/${businessID}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    let data = await response.json();
    data = data.statistics;

    // Map through the data to add the workingHours attribute
    const updatedData = data.map((item) => {
      const date = new Date(item.startMonthDate);
      const month = date.getUTCMonth(); // Get month (0-based)
      const year = date.getUTCFullYear(); // Get year

      // Find the corresponding workCalendar entry
      const workCalendarEntry = workCalendar.find(
        (cal) => cal.month === month && cal.year === year
      );

      // Add workingHours attribute
      return {
        ...item,
        workingHours: workCalendarEntry ? workCalendarEntry.workingHours : 0,
      };
    });

    return updatedData;
  } catch (error) {
    console.error("Error fetching statistics data:", error);
    throw error;
  }
};

//pivot data - shifts
export const getAllPivotShifts = async () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");

  try {
    // Fetch shifts
    const response = await fetch(`${shiftsEndpoint}/get-all/${businessID}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const shiftsData = await response.json();
    const shifts = shiftsData.shifts;

    if (!shifts || !Array.isArray(shifts)) {
      throw new Error("Invalid data structure from the server");
    }

    // Fetch employees
    const employees = await getEmployees();

    // Create a map of employeeID to employee full name
    const employeeMap = new Map();
    employees.forEach(({ employeeID, fullName }) => {
      employeeMap.set(employeeID, fullName);
    });

    // Fetch employees
    const units = await getBusinessUnits();

    // Create a map of employeeID to employee full name
    const unitMap = new Map();
    units.forEach(({ businessUnitID, name }) => {
      unitMap.set(businessUnitID, name);
    });

    // Mapping shifts and adding employee full name
    return shifts.map(
      ({
        shiftId,
        employeeID,
        shiftStartTime,
        shiftEndTime,
        businessUnitID,
      }) => {
        const duration =
          (new Date(shiftEndTime) - new Date(shiftStartTime)) /
          (1000 * 60 * 60); // Duration in hours
        const date = new Date(shiftStartTime).toLocaleDateString(); // Extract date from start time

        return {
          id: shiftId,
          category: formatMessage("shifts"),
          date: date,
          name: employeeMap.get(employeeID), // map employeeID to full name
          duration: duration,
          businessUnitID: unitMap.get(businessUnitID), // map businessUnitID to unit name
        };
      }
    );
  } catch (error) {
    console.error("Error fetching shifts or employees:", error);
    throw error;
  }
};

//pivot data - leave statistics
export const getPivotStatisticData = async () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${statisticsEndpoint}/get-all/${businessID}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log(data);

    if (!data || !Array.isArray(data.statistics)) {
      throw new Error("Invalid data structure from the server");
    }

    // Fetch employees
    const employees = await getEmployees();

    // Create a map of employeeID to employee full name
    const employeeMap = new Map();
    employees.forEach(({ employeeID, fullName }) => {
      employeeMap.set(employeeID, fullName);
    });

    // Mapping data and adding employee full name
    return data.statistics.map(
      ({
        id,
        startMonthDate,
        employeeID,
        leaveHoursPerMonth,
      }) => {
        const employeeName = employeeMap.get(employeeID);

        return {
          id: id,
          category: formatMessage("leaves"),
          date: startMonthDate,
          name: employeeName,
          duration: leaveHoursPerMonth,
        };
      }
    );
  } catch (error) {
    console.error("Error fetching statistics data:", error);
    throw error;
  }
};

/*
export const getAllPivotSLeaves = async () => {

  const emplLEaves = await getEmployeeLeaves();

  if (!emplLEaves || !Array.isArray(emplLEaves)) {
    throw new Error("Invalid data structure from the server");
  }

  // Fetch employees
  const employees = await getEmployees();

  // Create a map of employeeID to employee full name
  const employeeMap = new Map();
  employees.forEach(({ employeeID, fullName }) => {
    employeeMap.set(employeeID, fullName);
  });
// Mapping shifts and adding employee full name
return shifts.map(
  ({
    leaveId,
    employeeID,
    leaveType,
    shiftEndTime,
    businessUnitID,
  }) => {
    const duration =
      (new Date(shiftEndTime) - new Date(shiftStartTime)) /
      (1000 * 60 * 60); // Duration in hours
    const date = new Date(shiftStartTime).toLocaleDateString(); // Extract date from start time

    return {
      id: shiftId,
      category: "Shifts",
      date: date,
      name: employeeMap.get(employeeID), // map employeeID to full name
      duration: duration,
      businessUnitID: unitMap.get(businessUnitID), // map businessUnitID to unit name
    };
  }
);


}*/