import React, { useCallback } from 'react';
import List from 'devextreme-react/list';
import Button from 'devextreme-react/button';
import { useScreenSize } from '../../../utils/media-query';
import { AgendaListItem } from './agenda-list';
import './agenda.scss';

const Agenda = ({ selectedAppointmentData = { startDate: new Date() }, toggleOpen, resources, items, showAppointmentTooltip }) => {
  const { isLarge } = useScreenSize();
  const today = new Date();

  // Get the current locale
  const currentLocale = sessionStorage.getItem('locale') || 'en';

  const formattedStartDate = today.toLocaleDateString(currentLocale, {
    weekday: 'short',
    day: 'numeric',
    month: 'short'
  });

  return (
    <div className='agenda'>
      <div className='agenda-header'>
        <div className='date'>
          {formattedStartDate}
        </div>
        <Button
          icon={isLarge ? 'panelleft' : 'panel'}
          stylingMode='text'
          onClick={toggleOpen}
        />
      </div>
    </div>
  );
};

export default Agenda;
