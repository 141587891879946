import { HomePage, ProfilePage } from './pages';
import BusinessUnitPage from './pages/bunits/BusinessUnitPage';
import { withNavigationWatcher } from './contexts/navigation'; 
import EmployeePage from './pages/employee/EmployeePage';
import ContractsPage from './pages/contracts/ContractsPage';
import BusinessLeavesPage from './pages/businessLeaves/BusinessLeavesPage';
import EmployeeLeavesPage from './pages/employeeLeaves/employeeLeavesPage';
import EmployeePreferencesPage from './pages/preferences/EmployeePreferencesPage';
import StatisticPage from './pages/statistic/StatisticPage';



const routes = [
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/statiscs',
        element: StatisticPage
    },
    {
        path: '/employees',
        element: EmployeePage
    },
    {
        path: '/contracts',
        element: ContractsPage
    },
    {
        path: '/eleaves',
        element: EmployeeLeavesPage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/bunits',
        element: BusinessUnitPage
    },
    {
        path: '/bleaves',
        element: BusinessLeavesPage
    },
    {
        path: '/epreferences',
        element: EmployeePreferencesPage
    },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
