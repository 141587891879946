import React, { useState, useEffect, useCallback } from "react";
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  RequiredRule,
  RangeRule,
  Lookup,
  Form,
  Item,
  Button as GridButton,
} from "devextreme-react/data-grid";
import {
  getBusinessUnitsAndOpenHours,
  deleteBusinessUnit,
  createBusinessUnit,
  updateBusinessUnit,
  updateUnitOpeningHours,
  createOpeningsHours,
} from "../../api/bunits";
import { daysOfWeek, defaultOpeningHours } from "../enums";
import { formatMessage } from "devextreme/localization";
const currentLocale = sessionStorage.getItem("locale") || "en";

const BusinessUnitPage = () => {
  const [businessUnits, setBusinessUnits] = useState([]);
  const [currentOpeningHours, setCurrentOpeningHours] = useState([]);
  const [isNewRow, setIsNewRow] = useState(false);

  useEffect(() => {
    getBusinessUnitsAndOpenHours()
      .then((data) => {
        setBusinessUnits(data);
      })
      .catch((error) => {
        console.error("Error fetching business units:", error);
      });
  }, [setBusinessUnits]);

  const handleDelete = (e) => {
    const deletedBusinessUnit = e.data;
    deleteBusinessUnit(deletedBusinessUnit.businessUnitID)
      .then(() => {
        setBusinessUnits((prevState) =>
          prevState.filter(
            (unit) => unit.businessUnitID !== deletedBusinessUnit.businessUnitID
          )
        );
      })
      .catch((error) => {
        console.error("Error deleting business unit:", error);
      });
  };

  const handleCopyRow = (rowData) => {
    createBusinessUnit(rowData)
      .then((businessUnitID) => {
        console.log(rowData);
        return createOpeningsHours(rowData.openingHours, businessUnitID); // Correct chaining
      })
      .then(() => getBusinessUnitsAndOpenHours()) // Fetch updated data
      .then((data) => {
        setBusinessUnits(data);
        console.log(data);
        setCurrentOpeningHours(data.openingHours);
      })
      .catch((error) => {
        console.error("Error copying unit:", error);
      });
  };

  const handleCreate = (e) => {
    createBusinessUnit(e.data)
      .then((businessUnitID) => {
        return createOpeningsHours(currentOpeningHours, businessUnitID); // Correct chaining
      })
      .then(() => getBusinessUnitsAndOpenHours()) // Fetch updated data
      .then((data) => {
        setBusinessUnits(data);
        setCurrentOpeningHours(data.openingHours);
      })
      .catch((error) => {
        console.error("Error creating unit:", error);
      });
  };

  const handleSaveChanges = (e) => {
    const updatedBusinessUnit = e.data;
    const id = updatedBusinessUnit.businessUnitID;
    updateBusinessUnit(updatedBusinessUnit, id);
  };

  const onEditingStart = (e) => {
    setCurrentOpeningHours(e.data.openingHours);
  };

  const onInitNewRow = () => {
    setCurrentOpeningHours(defaultOpeningHours);
    setIsNewRow(true);
    
  };

  const onSaved = (e) => {
    e.cancel = true;
    //setIsNewRow(false);
  };

  const onSaving = useCallback(
    (e) => {
      if (e.changes.length) {
        const changes = e.changes.map((item) => ({
          unitOpeningHoursId:
            item.key.unitOpeningHoursId !== undefined
              ? item.key.unitOpeningHoursId
              : item.key.dayOfWeek,
          openingHoursStart:
            item.data.openingHoursStart !== undefined
              ? item.data.openingHoursStart
              : item.key.openingHoursStart,
          openingHoursEnd:
            item.data.openingHoursEnd !== undefined
              ? item.data.openingHoursEnd
              : item.key.openingHoursEnd,
          isOpen:
            item.data.isOpen !== undefined ? item.data.isOpen : item.key.isOpen,
          dayOfWeek: item.key.dayOfWeek,
          businessUnitID: item.key.businessUnitID,
          businessID: item.key.businessID,
        }));

        if (!isNewRow) {
          console.log("HELLO");
          updateUnitOpeningHours(changes);
        }
      }
    },
    [isNewRow, defaultOpeningHours]
  );

  return (
    <div className={"content-block"}>
      <h2>{formatMessage("businessUnits")}</h2>
      <DataGrid
        dataSource={businessUnits}
        keyExpr="businessUnitID"
        className={"dx-card wide-card"}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        rowAlternationEnabled={true}
        onRowRemoved={handleDelete}
        onRowInserted={handleCreate}
        onRowUpdated={handleSaveChanges}
        onEditingStart={onEditingStart}
        onInitNewRow={onInitNewRow}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          popup={{
            title: formatMessage("editBusinessUnit"),
            showTitle: true,
            width: 700,
            height: 650,
          }}
        >
          <Form>
            <Item dataField="name" dataType="string" colSpan={1}>
              <RequiredRule message={formatMessage("requiredMessage")} />
            </Item>
            <Item
              dataField="numberOfEmployees"
              dataType="number"
              colSpan={1}
              format={{
                type: "fixedPoint",
                positiveFormat: "$#0",
              }}
            >
              <RequiredRule />
              <RangeRule
                min={0}
                message={formatMessage("positiveNumberMessage")}
              />
            </Item>
            <Item colSpan={2} className={"bunit-form"}>
              <DataGrid
                dataSource={currentOpeningHours}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={false}
                rowAlternationEnabled={false}
                onSaving={onSaving}
                onSaved={onSaved}
                editing={{
                  mode: "cell",
                  allowUpdating: true,
                  allowDeleting: false,
                  allowAdding: false,
                }}
              >
                <Column
                  dataField="dayOfWeek"
                  caption={formatMessage("dayOfWeek")}
                  allowEditing={false}
                >
                  <Lookup
                    dataSource={daysOfWeek}
                    valueExpr="id"
                    displayExpr={currentLocale}
                  />
                </Column>
                <Column
                  dataField="isOpen"
                  caption={formatMessage("isOpen")}
                  dataType="boolean"
                />
                <Column
                  dataField="openingHoursStart"
                  caption={formatMessage("openingStart")}
                  dataType="time"
                />
                <Column
                  dataField="openingHoursEnd"
                  caption={formatMessage("openingEnd")}
                  dataType="time"
                />
              </DataGrid>
            </Item>
          </Form>
        </Editing>
        <Column dataField="name" caption={formatMessage("name")} />
        <Column
          dataField="numberOfEmployees"
          caption={formatMessage("employeeCapacity")}
        />
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton
            icon="copy"
            hint={formatMessage("name")}
            onClick={(e) => handleCopyRow(e.row.data)}
          />
          <GridButton name="delete" />
        </Column>
      </DataGrid>
    </div>
  );
};

export default BusinessUnitPage;
