import React, { useState, useEffect } from "react";
import "./profile.scss";
import Form from "devextreme-react/form";
import { getUserProfile } from "../../api/auth";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import service from "../../dictionary.js";

const ProfilePage = () => {
  const currentLocale = sessionStorage.getItem("locale") || "en";
  locale(currentLocale);
  //loadMessages(deMessages);
 // loadMessages(skMessages);
  loadMessages(service.getDictionary());
  const [userData, setUserData] = useState([]);
  const colCountByScreen = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };

  useEffect(() => {
    getUserProfile()
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{formatMessage("myProfile")}</h2>

      <div className={"content-block dx-card responsive-paddings"}>
        <Form
          className="profile-form"
          id={"form"}
          formData={userData}
          defaultFormData={userData}
          labelLocation={"top"}
          colCountByScreen={colCountByScreen}
          items={[
            {
              dataField: "email",
              label: { text: formatMessage("email") },
              editorOptions: { disabled: false },
            },
            {
              dataField: "firstName",
              label: { text: formatMessage("firstName") },
            },
            {
              dataField: "lastName",
              label: { text: formatMessage("lastName") },
            },
            {
              dataField: "businessId",
              label: { text: formatMessage("businessId") },
              editorOptions: { disabled: false },
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default ProfilePage;
