import React from 'react';
import Button from 'devextreme-react/button';
import './scheduler-tooltip.scss';
import { formatMessage, loadMessages } from "devextreme/localization";
import service from "../../../dictionary.js";

// Ensure messages are loaded once
loadMessages(service.getDictionary());

// Define the date and time format options
const dateOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const TooltipContentTemplate = ({ selectedAppointmentData, deleteCurrentAppointment, editCurrentAppointment }) => {
  if (!selectedAppointmentData || !selectedAppointmentData.endDate) {
    return null;
  }

  // Get the current locale
  const currentLocale = sessionStorage.getItem('locale') || 'en';

  // Format the start and end dates using the current locale
  const startDateString = new Intl.DateTimeFormat(currentLocale, dateOptions).format(selectedAppointmentData.startDate);
  const endDateString = new Intl.DateTimeFormat(currentLocale, timeOptions).format(selectedAppointmentData.endDate);

  // Combine the formatted dates into a single string
  const timeString = `${startDateString} - ${endDateString}`;

  return (
    <div className='appointment-tooltip'>
      <div className='title'>{selectedAppointmentData.text}</div>
      <div className='content'>
        <div className='date'>
          <Button
            icon='clock'
            stylingMode='text'
            focusStateEnabled={false}
            activeStateEnabled={false}
            hoverStateEnabled={false}
          />
          {timeString}
        </div>
        {selectedAppointmentData.description &&
          <div className='description'>
            <Button
              icon='textdocument'
              stylingMode='text'
              focusStateEnabled={false}
              activeStateEnabled={false}
              hoverStateEnabled={false}
            />
            {selectedAppointmentData.description}
          </div>
        }
      </div>
      <div className='buttons'>
        <Button
          className='button-success'
          text={formatMessage('edit')}
          type='success'
          stylingMode='text'
          onClick={editCurrentAppointment}
        />
        <Button
          className='button-danger'
          text={formatMessage('delete')}
          type='danger'
          stylingMode='text'
          onClick={deleteCurrentAppointment}
        />
      </div>
    </div>
  );
};
