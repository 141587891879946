import { SERVER_URL } from './config.js';
const contractEndpoint = `${SERVER_URL}/api/employee-contracts`;

//get all
export const getContracts = () => {
  const businessID = localStorage.getItem('businessId');
  const token = localStorage.getItem('token');
  return fetch(`${contractEndpoint}/get-all/${businessID}`,
  {
    method: "GET",
    headers: { 'Authorization': `Bearer ${token}` },
  }
  )
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.emplContracts; 
    })
    .catch(error => {
      console.error('Error fetching employee contracts:', error);
      throw error;
    });
};

//update
export const updateContract = (updatedContract, contractId) => {
  const token = localStorage.getItem('token');
  fetch(`${contractEndpoint}/${contractId}`, {
    method: "PUT", 
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedContract),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update  contract");
      }
      console.log("Employee updated successfully");
    })
    .catch((error) => {
      console.error("Error updating  contract:", error);
    });
}


//delete  by ID
export const deleteContract = (contractId) => {
  const token = localStorage.getItem('token');
  return fetch(`${contractEndpoint}/${contractId}`, {
    method: 'DELETE',
    'Access-Control-Allow-Origin': '*',
    headers: { 'Authorization': `Bearer ${token}` }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete  contract');
      }
    })
    .catch(error => {
      console.error('Error deleting  contracts:', error);
      throw error; 
    });
  };


//create
export const createContract = (newContract) => {
  const token = localStorage.getItem('token');
  const requestContract = {
    name: newContract.name,
    weekendDefinition: newContract.weekendDefinition,
    shiftPattern: newContract.shiftPattern,
    workingHoursPerDay: newContract.workingHoursPerDay,
    workingHoursPerWeek: newContract.workingHoursPerWeek,
    businessID: localStorage.getItem('businessId')
  }
  return fetch(`${contractEndpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(requestContract)
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to create contract');
    }
    console.log('New business unit created successfully');
  })
  .catch(error => {
    console.error('Error creating contract:', error);
  });
};