import { formatMessage } from 'devextreme/localization';

export const useNavigationItems = () => {
  return [
    {
      text: formatMessage('scheduler'),
      path: '/home',
      icon: 'home',
    },
    {
      text: formatMessage('statistics'),
      path: '/statiscs',
      icon: 'datausage',
    },
    {
      text: formatMessage('businessUnits'),
      path: '/bunits',
      icon: 'ordersbox',
    },
    {
      text: formatMessage('businessLeaves'),
      path: '/bleaves',
      icon: 'sun',
    },
    {
      text: formatMessage('contracts'),
      path: '/contracts',
      icon: 'textdocument',
    },
    {
      text: formatMessage('employees'),
      path: '/employees',
      icon: 'group',
    },
    {
      text: formatMessage('employeeLeaves'),
      path: '/eleaves',
      icon: 'airplane',
    },
    {
      text: formatMessage('employeePairPreferences'),
      path: '/epreferences',
      icon: 'optionsgear',
    },
    {
      text: formatMessage('myProfile'),
      path: '/profile',
      icon: 'user',
    },
  ];
};
