import { SERVER_URL } from './config.js';
const preferencesEndpoint = `${SERVER_URL}/api/employees-preferences`;

//get all
export const getPreferences = () => {
  const businessID = localStorage.getItem('businessId');
  const token = localStorage.getItem('token');
  return fetch(`${preferencesEndpoint}/get-all/${businessID}`,
  {
    method: "GET",
    headers: { 'Authorization': `Bearer ${token}` },
  }
  )
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.employeePairPreferences; 
    })
    .catch(error => {
      console.error('Error fetching employee preferences:', error);
      throw error;
    });
};

//update
export const updatePreference = (updatedPreference) => {
  const token = localStorage.getItem('token');
  fetch(`${preferencesEndpoint}/${updatedPreference.preferenceId}`, {
    method: "PUT", 
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedPreference),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update  preference");
      }
      console.log("Preference updated successfully");
    })
    .catch((error) => {
      console.error("Error updating  preference:", error);
    });
}


//delete  by IDs
export const deletePreference = (preferenceId) => {
  const token = localStorage.getItem('token');
  return fetch(`${preferencesEndpoint}/${preferenceId}`, {
    method: 'DELETE',
    'Access-Control-Allow-Origin': '*',
    headers: { 'Authorization': `Bearer ${token}` }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete preference');
      }
    })
    .catch(error => {
      console.error('Error deleting  contracts:', error);
      throw error; 
    });
  };


//create
export const createPreference = async (newPreference) => {
  const token = localStorage.getItem('token');
  const requestPreference = {
    employeeId1: newPreference.employeeId1,
    employeeId2: newPreference.employeeId2,
    isSeparate: newPreference.isSeparate,
    businessID: localStorage.getItem('businessId')
  }
  try {
        const response = await fetch(`${preferencesEndpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(requestPreference)
        });
        if (!response.ok) {
            throw new Error('Failed to create preference');
        }
        console.log('New employee preference created successfully');
    } catch (error) {
        console.error('Error creating contract:', error);
    }
};