import "./planning-scheduler.scss";
import { useState, useCallback, useMemo, useEffect } from "react";
import {
  Form,
  SimpleItem,
  GroupItem,
  ButtonItem,
  RequiredRule,
} from "devextreme-react/form";
import { Tooltip } from "devextreme-react/tooltip";
import Scheduler, { Resource, View } from "devextreme-react/scheduler";
import SpeedDialAction from "devextreme-react/speed-dial-action";
import Toast from "devextreme-react/toast";
import { confirm } from "devextreme/ui/dialog";
import { useScreenSize } from "../../utils/media-query";
import SideHeader from "../../components/scheduler/agenda/agenda";
import { useSchedulerLogic } from "./use-scheduler-logic";
import {
  generateShifts,
  deleteAllShifts,
  getAllShifts,
  updateShift,
  refillShifts,
} from "../../api/shifts";
import { CalendarList } from "../../components/scheduler/calendar-list/calendar-list";
import { RightSidePanel } from "../../components/scheduler/right-side-panel/right-side-panel";
import { TooltipContentTemplate } from "../../components/scheduler/scheduler-tooltip/scheduler-tooltip";
import skMessages from "../../sk.json";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import service from "../../dictionary.js";
import { orderModes, months } from "../enums";

const bunitGroups = ["businessUnitID"];
const emplGroups = ["employeeID"];

const currentLocale = sessionStorage.getItem("locale") || "en";
locale(currentLocale);
loadMessages(skMessages);
loadMessages(service.getDictionary());

const SchedulerPage = () => {
  
  const {
    currentView,
    rightPanelOpen,
    schedulerRef,
    schedulerCurrentDate,
    selectedAppointment,
    tooltipPosition,
    tooltipRef,
    deleteCurrentAppointment,
    editCurrentAppointment,
    onCurrentViewChange,
    onAppointmentClick,
    onAppointmentTooltipShowing,
    onAppointmentModified,
    onAppointmentAdded,
    onCellClick,
    showAppointmentCreationForm,
    toggleRightPanelOpen,
    employees,
    fetchShiftsEmployees,
    businessUnits,
    shifts,
    calendars,
  } = useSchedulerLogic();

  const { isGrand, isXSmall, isSmall } = useScreenSize();
  const [shiftsFiltered, setShiftsFiltered] = useState([]);
  const [orderModeSelected, setOrderMode] = useState(() => {
    return localStorage.getItem("orderModeSelected") || null;
  });
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const month = months.find((month) => month.en === localStorage.getItem("selectedMonth")|| month.sk === localStorage.getItem("selectedMonth"));
    return  month ?  month.value : null;
  });
  const [schedulerDate, setSchedulerDate] = useState(() => {
    return localStorage.getItem("optionDate") || schedulerCurrentDate;
  });
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const formData = {
    orderMode: orderModeSelected,
    month: selectedMonth,
  };
  
  const fetchAndFilterShifts = useCallback(() => {
    fetchShiftsEmployees().then(() => {
      getAllShifts().then((data) => {
        const removedResourceFilters = selectedCalendars.map(
          (calendar) => calendar.id
        );
        const filteredTasks = data.filter((shift) => {
          return !removedResourceFilters.includes(shift.businessUnitID);
        });
        setShiftsFiltered(filteredTasks);
      });
    });
  }, [fetchShiftsEmployees, selectedCalendars]);

  useEffect(() => {
    setShiftsFiltered(shifts);
    fetchAndFilterShifts();
  }, [shifts]);

  const handleSelectionChange = (e) => {
    setOrderMode(e.value);
    localStorage.setItem("orderModeSelected", e.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.value);
    const month = months.find((month) => month.value == e.value);
    localStorage.setItem("selectedMonth", month[currentLocale]);
  };

  const onAppointmentUpdated = useCallback(
    (e) => {
      if (
        e.appointmentData.startDate.toDateString() ===
        selectedAppointment?.data.startDate.toDateString()
      ) {
        updateShift(e.appointmentData, e.appointmentData.id)
          .then(() => {
            fetchAndFilterShifts();
            setToastMessage(formatMessage("shiftUpdated"));
            setShowToast(true);
            setToastType("info");
          })
          .catch((error) => {
            console.error("Error updating appointment:", error);
            setToastMessage(formatMessage("commonErrorMsg"));
            setShowToast(true);
            setToastType("error");
          });
      }
    },
    [selectedAppointment, fetchAndFilterShifts]
  );

  const onSelectedCalendarsChange = useCallback(
    (selectedCalendars) => {
      setSelectedCalendars(selectedCalendars);

      const removedResourceFilters = selectedCalendars.map(
        (calendar) => calendar.id
      );

      if (shifts) {
        const filteredTasks = shifts.filter((shift) => {
          return !removedResourceFilters.includes(shift.businessUnitID);
        });
        setShiftsFiltered(filteredTasks);
      }
    },
    [shifts]
  );

  const resourcesList = useMemo(() => {
    return businessUnits.reduce((res, calendarList) => {
      return res.concat(calendarList.items);
    }, []);
  }, [businessUnits]);


  const onGenerateShiftsClick = useCallback(() => {
    if (formData.orderMode || formData.month) {
      let result = confirm(
        formatMessage("generateConfirmation"),
        formatMessage("generateShifts")
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          generateShifts(formData.orderMode, formData.month)
            .then(() => {
              fetchAndFilterShifts();
              setToastMessage(formatMessage("shiftsCreated"));
              setShowToast(true);
              setToastType("info");
            })
            .catch((error) => {
              console.error("Error generating shifts:", error);
            });
        }
      });
    }
  }, [formData, shiftsFiltered, fetchAndFilterShifts]);



  const onRefillShiftsClick = useCallback(() => {
    if (formData.orderMode || formData.month) {
      let result = confirm(
        formatMessage("refillConfirmation"),
        formatMessage("refill")
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          refillShifts(formData.orderMode, formData.month)
            .then(() => {
              fetchAndFilterShifts();
              if (shiftsFiltered.length > 0) { 
                setToastMessage(formatMessage("shiftsCreated"));
                setShowToast(true);
                setToastType("info");
              }
            })
            .catch((error) => {
              console.error("Error generating shifts:", error);
            });
        }
      });
    }
  }, [formData, orderModeSelected, selectedMonth, fetchAndFilterShifts]);

  const onDeleteAllShiftsClick = useCallback(() => {
    let result = confirm(
      formatMessage("deleteConfirmation"),
      formatMessage("delete")
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        deleteAllShifts().then(() => {
          fetchAndFilterShifts();
          setToastMessage(formatMessage("shiftsDeleted"));
          setShowToast(true);
          setToastType("info");
        });
      }
    });
  }, [fetchAndFilterShifts]);

  const handlePropertyChange = (e) => {
    if (e.name === "currentDate") {
      setSchedulerDate(e.value);
      localStorage.setItem("optionDate", e.value);
    }
  };

  const renderResourceCell = (model) => {
    const view = localStorage.getItem("currentView");
    let emplShiftHoursLabel = "";

    if (view === formatMessage("emplTimeline")) {
      // Extract the scheduler date
      const schedulerDateObject = new Date(schedulerDate);

      const schedulerMonth = schedulerDateObject.getMonth();
      const schedulerYear = schedulerDateObject.getFullYear();

      const matchingShift = model.data.monthShiftHours.find((shift) => {
        const shiftDate = new Date(shift.startMonthDate);
        return (
          shiftDate.getMonth() === schedulerMonth &&
          shiftDate.getFullYear() === schedulerYear
        );
      });

      

      emplShiftHoursLabel = matchingShift
        ? matchingShift.currentTotalShiftHours +
          "h./" +
          matchingShift.workingHoursPerMonth +
          "h."
        : "";
      return (
        <div>
          {model.data.text} <br />
          <i style={{ color: "grey" }}>{emplShiftHoursLabel} </i>
        </div>
      );
    }
    return <div>{model.data.text}</div>;
  };

  const onAppointmentFormOpening = (e) => {
    e.popup.option({
      title: formatMessage("appointmentDetails"),
      width: 600,
    });
  
    e.form.option("items", [
      {
        label: {
          text: formatMessage("employee"),
        },
        editorType: "dxSelectBox",
        dataField: "employeeID",
        editorOptions: {
          items: employees,
          displayExpr: "text",
          valueExpr: "id",
        },
      },
      {
        label: {
          text: formatMessage("unit"),
        },
        editorType: "dxSelectBox",
        dataField: "businessUnitID",
        editorOptions: {
          items: businessUnits,
          displayExpr: "text",
          valueExpr: "id",
        },
      },
      {
        label: {
          text: formatMessage("shiftStart"),
        },
        editorType: "dxDateBox",
        dataField: "startDate",
        editorOptions: {
          displayFormat: "dd.MM.yyyy HH:mm",
          width: "100%",
          type: "datetime",
        },
        validationRules: [
          {
            type: "required",
            message: formatMessage("startDateRequired"),
          },
          {
            type: "custom",
            message: formatMessage("startDateValidationMessage"),
            validationCallback: (options) => {
              const startDate = e.form.getEditor("startDate").option("value");
              const endDate = e.form.getEditor("endDate").option("value");
              return new Date(startDate) < new Date(endDate);
            },
          },
        ],
      },
      {
        label: {
          text: formatMessage("shiftEnd"),
        },
        editorType: "dxDateBox",
        dataField: "endDate",
        editorOptions: {
          displayFormat: "dd.MM.yyyy HH:mm",
          width: "100%",
          type: "datetime",
        },
        validationRules: [
          {
            type: "required",
            message: formatMessage("endDateRequired"),
          },
          {
            type: "custom",
            message: formatMessage("endDateValidationMessage"),
            validationCallback: (options) => {
              const startDate = e.form.getEditor("startDate").option("value");
              const endDate = e.form.getEditor("endDate").option("value");
              return new Date(startDate) < new Date(endDate);
            },
          },
        ],
      },
    ]);
  };
  

  return (
    <div>
    <div className="view-wrapper-calendar">
      <div className="calendar-content">
        <div className="main-content">
          <Scheduler
            ref={schedulerRef}
            adaptivityEnabled={isXSmall}
            allDayPanelMode="hidden"
            defaultCurrentView="month"
            recurrenceEditMode="occurrence"
            dataSource={shiftsFiltered}
            height={isGrand ? "1182px" : "750px"}
            onOptionChanged={handlePropertyChange}
            currentDate={schedulerDate}
            currentView={currentView}
            onCurrentViewChange={onCurrentViewChange}
            onAppointmentAdded={onAppointmentAdded}
            onAppointmentClick={onAppointmentClick}
            onAppointmentUpdated={onAppointmentUpdated}
            onAppointmentDeleted={onAppointmentModified}
            onAppointmentFormOpening={onAppointmentFormOpening}
            onAppointmentTooltipShowing={onAppointmentTooltipShowing}
            resourceCellRender={renderResourceCell}
            showCurrentTimeIndicator={false}
            onCellClick={onCellClick}
            firstDayOfWeek={1}
            startDayHour={6}
            endDayHour={19}
          >
            <View type="day"/>
            <View type="workWeek"/>
            <View type="month" maxAppointmentsPerCell="auto" />
            <View
              name={formatMessage("emplTimeline")}
              type="timelineMonth"
              groups={emplGroups}
            />
            <View
              name={formatMessage("unitTimeline")}
              type="timelineMonth"
              groups={bunitGroups}
            />
            <View name={formatMessage("list")} type="agenda" />
            <Resource
              fieldExpr="businessUnitID"
              dataSource={businessUnits}
              label={formatMessage("unit")}
            />
            <Resource
              fieldExpr="employeeID"
              dataSource={employees}
              label={formatMessage("employee")}
            />
          </Scheduler>
          
          <SpeedDialAction
            icon="add"
            visible={isXSmall}
            onClick={showAppointmentCreationForm}
          />
          <Tooltip
            ref={tooltipRef}
            target={selectedAppointment?.target}
            showEvent="click"
            position={tooltipPosition}
          >
            <TooltipContentTemplate
              deleteCurrentAppointment={deleteCurrentAppointment}
              editCurrentAppointment={editCurrentAppointment}
              selectedAppointmentData={selectedAppointment?.data}
            />
          </Tooltip>
        </div>
        <RightSidePanel
          showOpenButton={true}
          isOpened={rightPanelOpen}
          toggleOpen={toggleRightPanelOpen}
        >
          <SideHeader
            selectedAppointmentData={selectedAppointment?.data}
            toggleOpen={toggleRightPanelOpen}
          />
          <div
            className={
              isXSmall || isSmall ? "left-content small" : "left-content"
            }
          >
            <Form
              formData={formData}
              onFieldDataChanged={({ dataField, value }) => {
                if (dataField === "orderMode") {
                  handleSelectionChange({ value });
                } else if (dataField === "month") {
                  handleMonthChange({ value });
                }
              }}
              labelMode="outside"
              labelLocation="left"
              style={{
                margin: "30px 0px 30px 15px",
                border: "none",
                background: "none",
                width: "90%",
              }}
            >
              <SimpleItem>
                <div className="right-header">
                  <div className="list-header">{formatMessage("generateShifts")}</div>
                </div>
              </SimpleItem>
              <GroupItem colCount={2} className="gen-input">
                <SimpleItem
                  colSpan={2}
                  dataField="orderMode"
                  editorType="dxSelectBox"
                  label={{ text: formatMessage("orderMode") }} 
                  editorOptions={{
                    dataSource: orderModes.map((mode) => ({
                      id: mode.id,
                      name: mode[currentLocale],
                      value: mode.value,
                    })),
                    valueExpr: "value",
                    displayExpr: "name",
                    placeholder: formatMessage("selectItem"),
                  }}
                  className="no-border-no-background-no-padding"
                >
                  <RequiredRule message={formatMessage("requiredMessage")} />
                </SimpleItem>
                <SimpleItem
                  colSpan={2}
                  dataField="month"
                  editorType="dxSelectBox"
                  label={{ text: formatMessage("month") }} 
                  editorOptions={{
                    dataSource: months.map((month) => ({
                      id: month.id,
                      name: month[currentLocale],
                      value: month.value,
                    })),
                    valueExpr: "value",
                    displayExpr: "name",
                    placeholder: formatMessage("selectMonth"),
                  }}
                >
                  <RequiredRule message={formatMessage("requiredMessage")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem
                colCount={3}
                tyle={{
                  margin: "30px 0px 30px 15px",
                }}
              >
                <ButtonItem
                  buttonOptions={{
                    stylingMode: "outlined",
                    text: formatMessage("generateShifts"),
                    type: "info",
                    useSubmitBehavior: true,
                    onClick: onGenerateShiftsClick,
                  }}
                />
                <ButtonItem
                  buttonOptions={{
                    stylingMode: "outlined",
                    text: formatMessage("refill"),
                    useSubmitBehavior: true,
                    onClick: onRefillShiftsClick,
                    disabled: (shiftsFiltered.length === 0),
                  }}
                ></ButtonItem>
                <ButtonItem
                  buttonOptions={{
                    stylingMode: "outlined",
                    text: formatMessage("delete"),
                    type: "danger",
                    onClick: onDeleteAllShiftsClick,
                  }}
                />
              </GroupItem>
            </Form>
            <CalendarList
              calendarItems={calendars}
              onSelectedCalendarsChange={onSelectedCalendarsChange}
            />
          </div>
        </RightSidePanel>
      </div>
      <Toast
        message={toastMessage}
        visible={showToast}
        type={toastType}
        displayTime={3000}
        onHiding={() => {
          setShowToast(false);
          setToastMessage(""); // Clear the message
        }}
        className="custom-toast"
        position={{
          my: "bottom right",
          at: "bottom right",
          of: window,
        }}
      />
    
    </div>
    
    </div>
  );
};

export default SchedulerPage;

