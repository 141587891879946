// config/constants.js
import { SERVER_URL } from './config.js';
import { calmColors as defaultColors } from '../pages/enums.js';

const shiftsEndpoint = `${SERVER_URL}/api/shifts`;

// Utility to get colored employees
const getColoredEmployees = (employees) => {
  return employees.map((item, index) => ({
    id: item.employeeID,
    text: item.fullName,
    monthShiftHours: item.monthEmployeeShifts,
    color: defaultColors[index % defaultColors.length], // Handles color assignment
  }));
};

// get employees with shifts month hours for employee timeline view
export const getEmployeesMonthHours = async () => {
  const businessID = localStorage.getItem('businessId');
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${shiftsEndpoint}/get-all-employees/${businessID}`,
      {
        method: "GET",
        headers: { 'Authorization': `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch employees. Please check your network connection or try again.');
    }

    const data = await response.json();
    return getColoredEmployees(data); 

  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};



//generate shifts
export const generateShifts = async (orderModeSelected, selectedMonth) => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");
  const request = {
    month: selectedMonth,
    businessID: businessID,
    orderMode: orderModeSelected,
  };
  try {
    const response = await fetch(`${shiftsEndpoint}/generate-shifts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error("Failed to generate shifts");
    }
    console.log("New shifts generated successfully");
  } catch (error) {
    console.error("Error generating shifts:", error);
  }
};

//refill shifts
export const refillShifts = async (orderModeSelected, selectedMonth) => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");
  const request = {
    month: selectedMonth,
    businessID: businessID,
    orderMode: orderModeSelected,
  };
  try {
    const response = await fetch(`${shiftsEndpoint}/refill-shifts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error("Failed to generate shifts");
    }
    console.log("New shifts generated successfully");
  } catch (error) {
    console.error("Error generating shifts:", error);
  }
};

//delete all
export const deleteAllShifts = async () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${shiftsEndpoint}/delete-all/${businessID}`, {
      method: "DELETE",
      "Access-Control-Allow-Origin": "*",
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw new Error("Failed to delete shifts");
    }
  } catch (error) {
    console.error("Error deleting shifts:", error);
    throw error;
  }
};

//get all
export const getAllShifts = async () => {
  const businessID = localStorage.getItem("businessId");
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${shiftsEndpoint}/get-all/${businessID}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    const sortedShifts = data.shifts.sort(
      (a, b) => a.employeeID - b.employeeID
    );

    // Mapping for shifts
    return sortedShifts.map((shift, index) => {
      return {
        id: shift.shiftId,
        text: shift.name,
        employeeID: shift.employeeID,
        startDate: new Date(shift.shiftStartTime),
        endDate: new Date(shift.shiftEndTime),
        businessUnitID: shift.businessUnitID,
        color: defaultColors[index % defaultColors.length], 
      };
    });
  } catch (error) {
    console.error("Error fetching shifts:", error);
    throw error;
  }
};

//update
export const updateShift = (updatedShift, shiftId) => {
  const token = localStorage.getItem("token");

  // Subtract 2 hours from start and end dates
  const startDate = new Date(updatedShift.startDate);
  startDate.setHours(startDate.getHours() + 2);

  const endDate = new Date(updatedShift.endDate);
  endDate.setHours(endDate.getHours() + 2);
  
  const requestUpdatedShift = {
    shiftId: updatedShift.id,
    name: updatedShift.text,
    date: updatedShift.startDate,
    shiftStartTime: startDate,
    shiftEndTime: endDate,
    employeeID: updatedShift.employeeID,
    businessUnitID: updatedShift.businessUnitID,
    businessID: localStorage.getItem("businessId"),
  };

  return fetch(`${shiftsEndpoint}/${shiftId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestUpdatedShift),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update shift");
      }
      return response.json();
    })
    .then((result) => {
      console.log("Shift updated successfully");
      return result.data; // Ensure this returns the data
    })
    .catch((error) => {
      console.error("Error updating shift:", error);
      throw error;
    });
};


//delete  by ID
export const deleteShift = async (shiftId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${shiftsEndpoint}/${shiftId}`, {
      method: "DELETE",
      "Access-Control-Allow-Origin": "*",
      headers: { Authorization: `Bearer ${token}`},
    });
    if (!response.ok) {
      throw new Error("Failed to delete shift");
    }
  } catch (error) {
    console.error("Error deleting shift:", error);
    throw error;
  }
};

//create
export const createShift = async (newShift) => {

  // Subtract 2 hours from start and end dates
  const startDate = new Date(newShift.startDate);
  startDate.setHours(startDate.getHours() + 2);

  const endDate = new Date(newShift.endDate);
  endDate.setHours(endDate.getHours() + 2);
  
  const token = localStorage.getItem("token");
  const requestShift = {
    name: "new shift",
    date: startDate,
    shiftStartTime: startDate,
    shiftEndTime: endDate,
    employeeID: newShift.employeeID,
    businessUnitID: newShift.businessUnitID,
    businessID: localStorage.getItem("businessId"),
  };
  try {
    const response = await fetch(`${shiftsEndpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestShift),
    });
    if (!response.ok) {
      throw new Error("Failed to create shift");
    }
    console.log("New shift created successfully");
  } catch (error) {
    console.error("Error creating shift:", error);
  }
};


