
import { SERVER_URL } from './config.js';
const employeeLeaveEndpoint = `${SERVER_URL}/api/employee-leaves`;

//get all
export const getEmployeeLeaves = () => {
  const token = localStorage.getItem('token');
  const businessID = localStorage.getItem('businessId');
  return fetch(`${employeeLeaveEndpoint}/get-all/${businessID}`,
  {
    method: "GET",
    headers: { 'Authorization': `Bearer ${token}` },
  }
  )
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.empLeaves; 
    })
    .catch(error => {
      console.error('Error fetching employee leaves:', error);
      throw error;
    });
};

//update
export const updateEmployeeLeave = (updatedELeave, leaveId) => {
  const token = localStorage.getItem('token');
  fetch(`${employeeLeaveEndpoint}/${leaveId}`, {
    method: "PUT", 
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedELeave),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update employee leave");
      }
      console.log("employee leave updated successfully");
    })
    .catch((error) => {
      console.error("Error updating employee leave:", error);
    });
}


//delete  by ID
export const deleteEmployeeLeave = (leaveId) => {
  const token = localStorage.getItem('token');
  return fetch(`${employeeLeaveEndpoint}/${leaveId}`, {
    method: 'DELETE',
    'Access-Control-Allow-Origin': '*',
    headers: { 'Authorization': `Bearer ${token}` },
  
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete employee leave');
      }
    })
    .catch(error => {
      console.error('Error deleting employee leave:', error);
      throw error; 
    });
  };

//create
export const createEmployeeLeave = (newELeave) => {
  const token = localStorage.getItem('token');
  const requestContract = {
    leaveType: newELeave.leaveType,
    startDate: newELeave.startDate,
    endDate: newELeave.endDate,
    employeeID: newELeave.employeeID,
    businessID: localStorage.getItem('businessId'),
  }
  return fetch(`${employeeLeaveEndpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(requestContract)
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to create business leave');
    }
    console.log('New employee leave created successfully');
  })
  .catch(error => {
    console.error('Error creating employee leave:', error);
  });
};