import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Lookup,
  RequiredRule,
  Button as GridButton,
} from "devextreme-react/data-grid";
import {
  getPreferences,
  createPreference,
  deletePreference,
  updatePreference
} from "../../api/epreferences";
import { getEmployees } from "../../api/employees";
import { formatMessage } from "devextreme/localization";

const EmployeePreferencesPage = () => {
  const [preferences, setPreferences] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getEmployees()
      .then((data) => {
        setEmployees(data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, []);

  useEffect(() => {
    getPreferences()
      .then((data) => {
        setPreferences(data);
      })
      .catch((error) => {
        console.error("Error fetching employees preferences:", error);
      });
  }, []);

  const handleDelete = (e) => {
    const deletedPreference = e.data;
    deletePreference(deletedPreference.preferenceId)
      .then(() => {
        setPreferences((prevState) =>
          prevState.filter(
            (preference) => preference.preferenceId !== deletedPreference.preferenceId)
        );
        console.log("Preference deleted:", deletedPreference);
      })
      .catch((error) => {
        console.error("Error deleting contract:", error);
      });
  };

  const handleCreate = (e) => {
    const newPreference = e.data;
    createPreference(newPreference);
  };

  const handleSaveChanges = (e) => {
    const updatedPreference = e.data;
    updatePreference(updatedPreference);
  };


  return (
    <div className={"content-block"}>
      <h2>{formatMessage("employeePairPreferences")}</h2>
      <DataGrid
        dataSource={preferences}
        keyExpr="preferenceId"
        className={"dx-card wide-card"}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        rowAlternationEnabled={true}
        onRowRemoved={handleDelete}
        onRowInserted={handleCreate}
        onRowUpdated={handleSaveChanges}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          popup={{
            title: formatMessage("edit"),
            showTitle: true,
            width: 600,
            height: 550,
          }}
        />

        <Column dataField="employeeId1" caption={formatMessage("employee1")}>
          <Lookup
            dataSource={employees}
            valueExpr="employeeID"
            displayExpr="fullName"
          />
          <RequiredRule />
        </Column>
        <Column dataField="employeeId2" caption={formatMessage("employee2")}>
          <Lookup
            dataSource={employees}
            valueExpr="employeeID"
            displayExpr="fullName"
          />
          <RequiredRule />
        </Column>
        <Column 
          dataField="isSeparate" 
          caption={formatMessage("separateShifts")} 
          dataType="boolean" 
          defaultValue={true} >
        </Column>       
        <Column type="buttons">
          <GridButton name="edit" hint={formatMessage("edit")}/>
          <GridButton name="delete"/>
        </Column>
      </DataGrid>
    </div>
  );
};

export default EmployeePreferencesPage;

