import { SERVER_URL } from './config.js';
const businesLeaveEndpoint = `${SERVER_URL}/api/business-leaves`;
//get all
export const getBusinesLeaves = () => {
  const businessID = localStorage.getItem('businessId');
  const token = localStorage.getItem('token');
  return fetch(`${businesLeaveEndpoint}/get-all/${businessID}`,
  {
    method: "GET",
    headers: { 'Authorization': `Bearer ${token}` },
  }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data.businessUnitLeaves;
    })
    .catch((error) => {
      console.error("Error fetching business leaves:", error);
      throw error;
    });
};

//update
export const updateBusinesLeave = (updatedBLeave, leaveId) => {
  const token = localStorage.getItem('token');
  fetch(`${businesLeaveEndpoint}/${leaveId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedBLeave),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update business leave");
      }
      console.log("Business leave updated successfully");
    })
    .catch((error) => {
      console.error("Error updating business leave:", error);
    });
};

//delete  by ID
export const deleteBusinesLeave = (leaveId) => {
  const token = localStorage.getItem('token');
  return fetch(`${businesLeaveEndpoint}/${leaveId}`, {
    method: "DELETE",
    "Access-Control-Allow-Origin": "*",
    headers: { 'Authorization': `Bearer ${token}` },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete business leave");
      }
    })
    .catch((error) => {
      console.error("Error deleting business leave:", error);
      throw error;
    });
};

//create
export const createBusinesLeave = (newBLeave) => {
  const token = localStorage.getItem('token');
  
  return fetch(`${businesLeaveEndpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(newBLeave),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to create business leave");
      }
      console.log("New business leave created successfully");
    })
    .catch((error) => {
      console.error("Error creating business leave:", error);
    });
};




/*
NEWWW
import { SERVER_URL } from './config.js';
const businesLeaveEndpoint = `${SERVER_URL}/api/business-leaves`;

//get all
export const getBusinesLeaves = () => {
  const businessID = localStorage.getItem('businessId');
  const token = localStorage.getItem('token');
  return fetch(`${businesLeaveEndpoint}/get-all/${businessID}`,
  {
    method: "GET",
    headers: { 'Authorization': `Bearer ${token}` },
  }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data.businessUnitLeaves;
    })
    .catch((error) => {
      console.error("Error fetching business leaves:", error);
      throw error;
    });
};

//update
export const updateBusinesLeave = (updatedBLeave, leaveId) => {
  const token = localStorage.getItem('token');
  fetch(`${businesLeaveEndpoint}/${leaveId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedBLeave),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update business leave");
      }
      console.log("Business leave updated successfully");
    })
    .catch((error) => {
      console.error("Error updating business leave:", error);
    });
};

//delete  by ID
export const deleteBusinesLeave = (leaveId) => {
  const token = localStorage.getItem('token');
  return fetch(`${businesLeaveEndpoint}/${leaveId}`, {
    method: "DELETE",
    "Access-Control-Allow-Origin": "*",
    headers: { 'Authorization': `Bearer ${token}` },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete business leave");
      }
    })
    .catch((error) => {
      console.error("Error deleting business leave:", error);
      throw error;
    });
};

//create
export const createBusinesLeave = (newBLeave) => {
  const token = localStorage.getItem('token');
  
  return fetch(`${businesLeaveEndpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(newBLeave),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to create business leave");
      }
      console.log("New business leave created successfully");
    })
    .catch((error) => {
      console.error("Error creating business leave:", error);
    });
};
*/