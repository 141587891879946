export const defaultOpeningHours = [
  {
    dayOfWeek: 1,
    openingHoursStart: "07:00:00",
    openingHoursEnd: "18:00:00",
    isOpen: true,
  },
  {
    dayOfWeek: 2,
    openingHoursStart: "07:00:00",
    openingHoursEnd: "18:00:00",
    isOpen: true,
  },
  {
    dayOfWeek: 3,
    openingHoursStart: "07:00:00",
    openingHoursEnd: "18:00:00",
    isOpen: true,
  },
  {
    dayOfWeek: 4,
    openingHoursStart: "07:00:00",
    openingHoursEnd: "18:00:00",
    isOpen: true,
  },
  {
    dayOfWeek: 5,
    openingHoursStart: "07:00:00",
    openingHoursEnd: "18:00:00",
    isOpen: true,
  },
  {
    dayOfWeek: 6,
    openingHoursStart: "00:00:00",
    openingHoursEnd: "00:00:00",
    isOpen: false,
  },
  {
    dayOfWeek: 0,
    openingHoursStart: "00:00:00",
    openingHoursEnd: "00:00:00",
    isOpen: false,
  },
];

 export const daysOfWeek = [
    { id: 1, sk: "Pondelok", en: "Monday" },
    { id: 2, sk: "Utorok", en: "Tuesday" },
    { id: 3, sk: "Streda", en: "Wednesday" },
    { id: 4, sk: "Štvrtok", en: "Thursday" },
    { id: 5, sk: "Piatok", en: "Friday" },
    { id: 6, sk: "Sobota", en: "Saturday" },
    { id: 0, sk: "Nedeľa", en: "Sunday" },
  ];

  export const orderModes = [
    { id: 1, en: "SkillRate", sk: "Podľa zručností", value: "SkillRate" },
    { id: 2, en: "EvenlyHours", sk: "Rovnomerne hodiny", value: "EvenlyHours" },
    { id: 3, en: "SkillRateEvenly", sk: "Podľa zručností + Rovnomerne hodiny", value: "SkillRateEvenly" },
    { id: 4, en: "Random", sk: "Náhodne", value: "Random" },
  ];
  
  export const months = [
    { id: 1, value: 1, en: "January", sk: "Január" },
    { id: 2, value: 2, en: "February", sk: "Február" },
    { id: 3, value: 3, en: "March", sk: "Marec" },
    { id: 4, value: 4, en: "April", sk: "Apríl" },
    { id: 5, value: 5, en: "May", sk: "Máj" },
    { id: 6, value: 6, en: "June", sk: "Jún" },
    { id: 7, value: 7, en: "July", sk: "Júl" },
    { id: 8, value: 8, en: "August", sk: "August" },
    { id: 9, value: 9, en: "September", sk: "September" },
    { id: 10, value: 10, en: "October", sk: "Október" },
    { id: 11, value: 11, en: "November", sk: "November" },
    { id: 12, value: 12, en: "December", sk: "December" },
  ];
  

  export const workCalendar = [
    { month: 0, year: 2024, workingHours: 176 }, // January (0-based month)
    { month: 1, year: 2024, workingHours: 168 }, // February
    { month: 2, year: 2024, workingHours: 160 }, // March
    { month: 3, year: 2024, workingHours: 168 }, // April
    { month: 4, year: 2024, workingHours: 168 }, // May
    { month: 5, year: 2024, workingHours: 160 }, // June
    { month: 6, year: 2024, workingHours: 176 }, // July
    { month: 7, year: 2024, workingHours: 168 }, // August
    { month: 8, year: 2024, workingHours: 168 }, // September
    { month: 9, year: 2024, workingHours: 184 }, // October
    { month: 10, year: 2024, workingHours: 160 }, // November
    { month: 11, year: 2024, workingHours: 152 }  // December
  ];

  export const calmColors = [
    "#D4A5A5", // Pale Pink
    "#C1D3E0", // Ice Blue
    "#BFD8B8", // Mint
    "#E6D3C1", // Light Sand
    "#EAD1DC", // Soft Pink
    "#C9D2D0", // Misty Gray
    "#FFEBCC", // Soft Apricot
    "#C8D6CA", // Light Green
    "#FF8C00", // Dark Orange
    "#7EBDC3", // Sky Blue
    "#D5D6EA", // Light Periwinkle
    "#B4A7D6", // Periwinkle
    "#FFD3B6", // Light Peach
    "#CDE0C9", // Pale Mint
    "#F2C6DE", // Light Pink
    "#D0E1F9", // Light Blue
    "#FFBCBC", // Peach
    "#FFB6C1", // Light Coral
    "#FFDEAD", // Navajo White
    "#E0BBE4", // Lilac
    "#FFF2CC", // Soft Yellow
    "#B5EAD7", // Seafoam
    "#E5D9B6", // Light Cream
    "#D5E8D4", // Pastel Green
    "#FFDAC1", // Apricot
    "#D8E2DC", // Soft Green
    "#E0E1E0", // Light Gray
    "#C3D5E8", // Powder Blue
    "#AFC9FF", // Baby Blue
    "#F6E3BC", // Light Yellow
    "#D9C2D9", // Lavender
    "#D9D3B4", // Beige
    "#D3C0C0", // Mauve
    "#FAFAD2", // Light Goldenrod Yellow
    "#E2D8C0", // Sand
    "#FFE4E1", // Blush
    "#F4D4D4", // Light Rose
    "#DB7093", // Pale Violet Red
    "#B4C3A7", // Sage Green
    "#FFFACD", // Lemon Chiffon
];

  export const leaveType = [
    { id: 1, value: "nholiday", en: "National holiday", sk: "Štátny sviatok" },
    { id: 2, value: "teambuilding", en: "Teambuilding", sk: "Teambuilding" },
    { id: 3, value: "other", en: "Other", sk: "Iné" },
  ];
  
  export const shiftPattern = [
    { id: 1, value: "flex", en: "Standard week", sk: "Štandardný týždeň" },
    { id: 2, value: "2to2", en: "2 work 2 weekend", sk: "2 práca / 2 víkend" },
    { id: 3, value: "ShLg", en: "Short long week", sk: "Krátky / dlhý týždeň" },
  ];
  
  export const weekendDefinition = [
    { id: 1, value: "sunday", en: "Sunday", sk: "Nedeľa" },
    { id: 2, value: "satSun", en: "Saturday, Sunday", sk: "Sobota - Nedeľa" },
  ];
  
  export const emplLeaveType = [
    { id: 1, value: "holiday", en: "Holiday", sk: "Dovolenka" },
    { id: 2, value: "sick", en: "Sick", sk: "PN" },
    { id: 3, value: "doctor", en: "Doctor visit", sk: "Návšteva lekára" },
    { id: 4, value: "unpayed", en: "Unpaid leave", sk: "Neplatené voľno" },
    { id: 5, value: "other", en: "Other", sk: "Iné" },
    { id: 6, value: "auto", en: "Auto-generated", sk: "Automaticky generované" },
  ];
