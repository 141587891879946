import Query from 'devextreme/data/query';

const findAllAppointmentsForDay = (selectedAppointment, dataSource) => {
  if (!dataSource) {
    return [];
  }
  const appointments = dataSource.items();
  if (appointments.length === 0 || !selectedAppointment || !selectedAppointment.startDate) {
    return [];
  }
  return Query(appointments)
    .filter((appointment) => {
      const startDate = appointment.startDate;
      if (!startDate) {
        return false;
      }
      return startDate.getDate() === selectedAppointment.startDate.getDate() &&
        startDate.getMonth() === selectedAppointment.startDate.getMonth();
    })
    .toArray();
};

export { findAllAppointmentsForDay };
